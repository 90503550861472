'use strict';

const carousels = require('./carousels');
const timeslot = require('./timeslot');
const shipping = require('../checkout/shipping');
const googlePlaces = require('./googlePlacesAutocomplete');
const DOMUtils = require('../checkout/DOMUtils');

const DELIVERY_METHODS = {
    DOMICILIO: 'Delivery',
    LOCKER: 'ClickAndCollect',
    TIENDA: 'InStorePickUp'
};

/**
 * setNextAvailableSlot asyncronous
 */
const setNextAvailableSlot = () => {
    const url = $('.nextAvailableSlot').data('action');
    const browserData = JSON.stringify({
        colorDepth: screen.colorDepth,
        screenHeight: screen.height,
        screenWidth: screen.width,
        javaEnabled: navigator.javaEnabled(),
        timezoneOffset: new Date().getTimezoneOffset(),
        canMakeApplePayPayments: !!(window.ApplePaySession && window.ApplePaySession.canMakePayments())
    });

    $.ajax({
        url,
        data: { browserData },
        type: 'post',
        dataType: 'html',
        success(response) {
            $('.nextAvailableSlot').empty();
            $('.nextAvailableSlot').append(response);
        }
    });
};

/**
 * Reloads the time slot form for a new address based on the provided postal code element's value.
 * @param {jQuery} postalCodeElement - The jQuery element containing the postal code to use for reloading the time slot form.
 */
const reloadTimeSlotNewAddressForm = (postalCodeElement, streetElement, numberElement, cityElement) => {
    if (postalCodeElement.val().length === 5) {
        $('#timeslot-selected-timeslot').val('');
        timeslot.reloadDeliveryTimeSlot(
            postalCodeElement.data('url'),
            postalCodeElement.val(),
            streetElement.val(),
            numberElement.val(),
            cityElement.val()
        );
    }
};

/**
 * Serializes the selected time slot and shipping method into a query string format.
 * @returns {string} A serialized string of the selected time slot and shipping method.
 */
const timeSlotSerializedData = () => {
    const selectedTimeSlot = $('#timeslot-selected-timeslot');
    const selectedShippingMethod = $('#timeslot-shipping-method');
    const slotValue = selectedTimeSlot.val() !== '' ? JSON.stringify(JSON.parse(selectedTimeSlot.val())) : selectedTimeSlot.val();

    return `&${selectedTimeSlot.attr('name')}=${slotValue}&${selectedShippingMethod.attr('name')}=${selectedShippingMethod.val()}`;
};

/**
 * Validates that all required input fields are filled out.
 * @returns {boolean} True if all fields are filled, false otherwise.
 */
const isPersonalDataCompleted = () => {
    const firstname = $('#firstname').val();
    const lastname = $('#lastname').val();
    const nif = $('#nif').val();
    const birthdate = $('#birthdate').val();
    const phone = $('#phone').val();
    const email = $('#email').val();

    return firstname && lastname && nif && birthdate && phone && email;
};

/**
 * Activates the checkout button by enabling it, removing the deactivated class, and adding the gold class.
 */
const activateCheckoutButton = () => {
    const $confirmButton = $('button[value="submit-payment"]');
    if ($confirmButton.length && isPersonalDataCompleted()) {
        $confirmButton.removeAttr('disabled');
        $confirmButton.removeClass('btn-fill--deactivated');
        $confirmButton.addClass('btn-fill--gold');

        $('button.tabs-ak__element-checkout-button-overlay').css('display', 'none');
    }
};

$(() => {
    $(document).off('change', 'select[class*="select-"], .options-select');

    // Retrieves the first available timeslot window to be displayed in the header.
    if ($('.nextAvailableSlot').length > 0) {
        setNextAvailableSlot(this);
    }

    // Initialize time slots sliders
    document.querySelectorAll('.js-swiper').forEach(carousels.methods.initializeSwiper);

    /**
     * Handles click events on navigation buttons within a timeslot table to slide a swiper container forwards or backwards.
     * @param {Event} e - The event object which contains information about the click event.
     */
    $(document).on('click', '.timeslot-table .prev, .timeslot-table .next', (e) => {
        const timeslotTable = e.currentTarget.closest('.timeslot-table');
        const swiperContainer = timeslotTable.querySelector('.js-swiper');
        if (e.currentTarget.classList.contains('prev')) {
            swiperContainer.swiper.slidePrev();
        } else {
            swiperContainer.swiper.slideNext();
        }
    });

    /**
     * Handles click events on date elements within a timeslot table (days) to navigate the swiper container to a specific slide.
     * @param {Event} e - The event object which provides context and details about the click event, including the target element.
     */
    $(document).on('click', '.timeslot-table__days .timeslot-table__date-element', (e) => {
        const timeslotTable = e.currentTarget.closest('.timeslot-table');
        const swiperContainer = timeslotTable.querySelector('.js-swiper');
        const slideIndex = parseInt($(e.currentTarget).data('slide'), 10);
        swiperContainer.swiper.slideTo(slideIndex);
    });

    /**
     * Handles click events on slot content (timeslot) elements within a timeslot table.
     * It deselects any previously selected elements, marks the clicked element as selected.
     * @param {Event} e - The event object that contains details about the click event, including the target element.
     */
    $(document).on('click', '.timeslot-table .timeslot-table__slot-content-element', (e) => {
        if ($(e.currentTarget).hasClass('disabled')) {
            return;
        }

        const dateElements = e.currentTarget.closest('.timeslot-table').querySelectorAll('.timeslot-table__slot-content-element');
        dateElements.forEach((dateElement) => {
            dateElement.classList.remove('selected');
        });

        e.currentTarget.classList.add('selected');

        // Saves slot selection data to be ready for submission.
        $('#selectSlotModal .invalid-feedback').removeClass('u-show');
        $('#timeslot-selected-timeslot').val(JSON.stringify({
            carrierId: $(e.currentTarget).parent().data('carrier'),
            start: $(e.currentTarget).data('start'),
            end: $(e.currentTarget).data('end')
        }));

        // Saves shipping method
        const shippingMethod = $('#shippingMethodsModalList').length
            ? $('#shippingMethodsModalList li a.active').attr('id').replace(/-?tab/g, '')
            : $('#shippingMethodsList li a.active').attr('id').replace(/-?tab/g, '');
        $('#timeslot-shipping-method').val(shippingMethod);

        if (!$('#selectSlotModal').length) {
            $('#timeslot-selected-timeslot').trigger('change');
        }
    });

    /**
     * Updates shipment method every time user selects a timeslot on Checkout
     */
    $(document).on('change', '#timeslot-selected-timeslot', (e) => {
        e.preventDefault();

        // Clear previous errors if any
        $('div[id^="invalid-feedback-"]').removeClass('invalid-feedback-');
        $('.delivery-custom .invalid-feedback').removeClass('u-show');

        const slotData = $('#timeslot-selected-timeslot').val();
        const shippingMethod = $('#timeslot-shipping-method').val();

        if (slotData === '' || shippingMethod === '') {
            $('.delivery-custom .invalid-feedback').addClass('u-show');
            return;
        }

        const url = $('body').data('endpoint-confirm-slot');

        let addressForm = $('.delivery-custom .delivery-add-address').find('input, select').serialize();
        if ([shipping.method.CLICK_COLLECT, shipping.method.IN_STORE_PICKUP].includes(shippingMethod)) {
            addressForm = $(`div[data-shipping-method="${shippingMethod}"] div:first`).find('input, select').serialize();
            addressForm += timeSlotSerializedData();
        }
        $.spinner().start();
        $.ajax({
            url,
            method: 'POST',
            data: addressForm,
            success: (data) => {
                $('.delivery-custom input[name="csrf_token"]').val(data.token);

                activateCheckoutButton();

                if (!data.success) {
                    for (const key in data.fields) { // eslint-disable-line
                        $(`#invalid-feedback-${key}`).html(data.fields[key]).addClass('u-show');
                    }
                    $.spinner().stop();
                } else if (data.reload) {
                    $('#pricebookChangeModal').modal('show');
                    $.spinner().stop();
                } else if (data.CartUrl) {
                    $.ajax({
                        url: data.CartUrl,
                        method: 'get',
                        success: (basket) => {
                            DOMUtils.updateTotalsCart(basket.totals);
                        },
                        complete: () => {
                            $.spinner().stop();
                        }
                    });
                }
            },
            error: () => {
                $.spinner().stop();
            }
        });
    });

    /**
     * Reload page when the user closes the pricebook change modal.
     */
    $('#pricebookChangeModal').on('hidden.bs.modal', () => {
        window.location.reload();
    });

    /**
     * Handles change event when the user enter a new delivery address
     * Updates the delivery slots section of the webpage based on the response and initializes a swiper if necessary.
     * @param {Event} e - The event object that contains details about the click event, including the target element.
     */
    $(document).on('change', '.js-delivery-postal-code, .js-delivery-new-address, .js-delivery-number, .js-delivery-city', () => {
        reloadTimeSlotNewAddressForm(
            $('.js-delivery-postal-code'),
            $('.js-delivery-new-address'),
            $('.js-delivery-number'),
            $('.js-delivery-city')
        );
    });

    /**
     * Attaches a change event listener to radio buttons for changing the delivery address
     * Updates the delivery slots section of the webpage based on the response and initializes a swiper if necessary.
     * @param {Event} e - The event object which includes details about the target element of the change event.
     */
    $(document).on('change', 'input[type="radio"].js-delivery-address', (e) => {
        const currentElement = $(e.currentTarget);
        timeslot.reloadDeliveryTimeSlot(
            currentElement.data('url'),
            currentElement.data('postalCode'),
            currentElement.data('address'),
            currentElement.data('number'),
            currentElement.data('city')
        );

        // Saves shipping address ID to be ready for submission.
        if ($('#timeslot-addressId_modal').length) {
            $('#timeslot-addressId_modal').val(currentElement.val());
        }
    });

    /**
     * Displays the timeslot selection modal and initializes the timeslot sliders.
     */
    $(document).on('click', '.js-timeslots-modal', (e) => {
        e.preventDefault();
        const currentTarget = $(e.currentTarget);
        const url = currentTarget.data('url');

        if (!currentTarget.data('loading')) {
            currentTarget.data('loading', true);

            $.ajax({
                url: url,
                method: 'GET',
                success: (data) => {
                    if (data.template) {
                        const container = document.querySelector('div.js-timeslots-container');
                        container.innerHTML = data.template;

                        if (apiKey) {
                            googlePlaces.loadScript(
                                `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initAutocomplete&async=true`,
                                !$('#js-google-places').length
                            );
                        }

                        $('#selectSlotModal').modal();

                        if (data.swiper) {
                            const swiperContainer = container.querySelector('div[data-slot-type="delivery"] .js-swiper');
                            carousels.methods.initializeSwiper(swiperContainer);
                        }

                        window.storeData = data.stores;
                        window.lockerData = data.lockers;
                    }
                },
                complete: () => {
                    currentTarget.data('loading', false);

                    timeslot.updateShippingSelectors('stores');
                    timeslot.updateShippingSelectors('lockers');
                }
            });
        }
    });

    /**
     * Closes the timeslot selection modal window. It's fired when user clicks outside the modal window.
     */
    $(document).on('hidden.bs.modal', () => {
        if ($('#selectSlotModal button.close').length) {
            $('#selectSlotModal button.close').trigger('click');
        }
    });

    /**
     * Bootstrap bug: when closing the modal, the body class 'modal-open' is not removed.
     */
    $('body').on('click', '#selectSlotModal .close', () => {
        $('body').removeClass('modal-open');
    });

    /**
     * When modal is closed data-slot info is stored in the value attribute to be able to submit this data.
     */
    $(document).on('shown.bs.modal', () => {
        if ($('#selectSlotModal button.close').length) {
            const slotData = $('#timeslot-selected-timeslot').data('slot');
            if (slotData) {
                $('#timeslot-selected-timeslot').val(JSON.stringify(slotData));
            }
        }
    });

    /**
     * Closes the timeslot selection modal window by removing embedded HTML from the DOM
     */
    $(document).on('click', '#selectSlotModal button.close', () => {
        $('div.js-timeslots-container').empty();
        $('.modal-backdrop').remove();
    });

    /**
     * Saves the user's shipping method and time slot selection to update the cart and prefill this information at checkout.
     */
    $(document).on('click', '.js-submit-timeslot', (e) => {
        e.preventDefault();

        // Clear previous errors if any
        $('#selectSlotModal .invalid-feedback').removeClass('u-show');

        const slotData = $('#timeslot-selected-timeslot').data('slot') ? $('#timeslot-selected-timeslot').data('slot') : $('#timeslot-selected-timeslot').val();
        const shippingMethod = $('#timeslot-shipping-method').val();

        if (!slotData) {
            $('#selectSlotModal .invalid-feedback').addClass('u-show');
            return;
        }

        let addressForm = $('#selectSlotModal .delivery-add-address').find('input, select').serialize();
        if ([shipping.method.CLICK_COLLECT, shipping.method.IN_STORE_PICKUP].includes(shippingMethod)) {
            addressForm = $(`div[data-shipping-method="${shippingMethod}"] div:first`).find('input, select').serialize();
            addressForm += timeSlotSerializedData();
        }

        $.ajax({
            url: $(e.currentTarget).data('url'),
            method: 'POST',
            data: addressForm,
            success: (data) => {
                if (data.success) {
                    if (data.reload) {
                        $('#selectSlotModal button.close').trigger('click');
                        $('#pricebookChangeModal').modal('show');
                    } else {
                        location.reload();
                    }
                } else {
                    $('#selectSlotModal input[name="csrf_token"]').val(data.token);
                    if ([shipping.method.CLICK_COLLECT, shipping.method.IN_STORE_PICKUP].includes(shippingMethod)) {
                        const keys = Object.keys(data.fields);
                        if (keys.length > 0) {
                            const firstKey = keys[0];
                            $(`div[data-shipping-method="${shippingMethod}"] div.invalid-feedback.header`).html(data.fields[firstKey]).addClass('u-show');
                        }
                    } else {
                        for (const key in data.fields) { // eslint-disable-line
                            $(`#invalid-feedback-${key}`).html(data.fields[key]).addClass('u-show');
                        }
                    }
                }
            }
        });
    });

    /**
     * When selecting a new shipping address, deactivates the previous selection of saved addresses
     * and reloads the timeslots for the postal code from the form, if available.
     */
    $(document).on('click', '.add-address', () => {
        $('#timeslot-addressId_modal').val('');

        const $radioButtons = $('input[name="address-domicilio-addressUUID"]');
        if ($radioButtons.length) {
            $radioButtons.prop('checked', false);
        }

        if ($('#address-domicilio-new').length) {
            $('#address-domicilio-new').prop('checked', true);

            const message = $('div[data-shipping-method="' + DELIVERY_METHODS.DOMICILIO + '"]').data('noslots');
            $('div[data-slot-type="delivery"]').html(`<div class="tab-content__grid-right-box"><div class="timeslot-table no-slots"><p>${message}</p></div></div>`);
            reloadTimeSlotNewAddressForm($('.js-delivery-postal-code'), $('.js-delivery-new-address'), $('.js-delivery-number'), $('.js-delivery-city'));
        }
    });

    /**
     * When canceling the new address selection, deactivates that selection and checks the first address
     * from the address list as the default selection.
     */
    $(document).on('click', '.cm-cancel-add-address', () => {
        if ($('#address-domicilio-new').length) {
            $('#address-domicilio-new').prop('checked', false);
        }

        const $firstRadio = $('input[name="address-domicilio-addressUUID"]').first();
        if ($firstRadio.length) {
            $firstRadio.prop('checked', true);
        }

        $firstRadio.trigger('change');
    });

    /**
     * Handles the 'shown.bs.modal' event for the '#selectSlotModal' modal.
     * Preloads the time slot modal with previously selected shipment data.
     * Processes the selected timeslot data and triggers appropriate actions based on the delivery method.
     */
    $(document).on('shown.bs.modal', '#selectSlotModal', timeslot.preloadTimeslotSelection);
});
